import ReactDOM from 'react-dom';
import {createBrowserRouter, Navigate, RouterProvider} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'
import HomePage from "./Page/HomePage/HomePage";
import AboutUs from "./Page/AboutUsPage/AboutUs";
import Cyntics from "./Page/CYNTICS";
import ParticipantsComment from "./Page/ParticipantsComment";
import IIEchoOfPain from "./Page/IIEchoOfPain";
import './index.css'
import IndexPage from "./Page/Index/home_index";
import IUtopianThough from "./Page/IUtopianThough/IUtopianThough";
import './localization/i18n'


const router = createBrowserRouter([
  {
    path: "/",
    element: < IndexPage/>,
    children: [
      {
        index:true,
        element:<Navigate to={'/Homepage'}/>,
      },
      {
        path: 'Homepage',
        element: <HomePage/>,
      },
      {
        path: 'AboutUs',
        element: <AboutUs/>,
      },
      {
        path: 'IUtopianThoughts',
        element: <IUtopianThough/>,
      },
      {
        path: 'CYNTICS',
        element: <Cyntics/>,
      },
      {
        path: 'ParticipantsComments',
        element: <ParticipantsComment/>,
      },
      {
        path: 'IIEchoOfPain',
        element: <IIEchoOfPain/>,
      },
    ],
  },
]);


const App = () => {


  return <div className='App'>
    <RouterProvider router={router}/>
  </div>
}

ReactDOM.render(<App/>, document.getElementById('app'));