import React from 'react';


let arr = Array.from({length: 64}).map((item, index) => {
  return index;
});


const Cyntics = () => {
  return (
      <div className={'d-flex justify-content-center'} style={{backgroundColor: '#1e2025'}}>
        <div className={'row container'}>
          {
            arr.map(value => {
              return   <img key={value} className={'col-12 col-lg-6 p-0 mb-4'} src={`../assets/site/Page${value+1}.png`}/>;
            }
              )
          }

        </div>
      </div>
  );
};

export default Cyntics;


