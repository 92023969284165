import i18n from "i18next";
import {initReactI18next} from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      //Navbar
      'nav-hp': 'Homepage',
      'nav-au': 'About us',
      'nav-iut': 'I Utopian Thoughts',
      'nav-cyn': 'CYNTICS',
      'nav-pc': 'Participants Comments',
      'nav-iep': 'II Echo of Pain',
      //----------HomePage----------
      'hp-description-p1': '',
      'hp-description-p2': '',
      'hp-description-p3': '',
      'hp-description-p4': '',
      'hp-description-p5': '',
      'hp-description-p6': '',
      'hp-lesson-title':'Season #1 : Utopian thoughts',
      'hp-lesson1-title': '  Introduction to Dystopian Novels',
      'hp-lesson1-description': 'Starting from the origins of dystopian novels …',
      'hp-lesson2-title': 'Background of Orwell\'s "1984"',
      'hp-lesson2-description': 'What were the intentions and reasons behind George Orwell\'s masterpiece?',
      'hp-lesson3-title': ' Introduction to Research',
      'hp-lesson3-description': 'How could we do a \'good\' research?',
      'hp-lesson4-title': 'Using Art as Means of Political Advertisement',
      'hp-lesson4-description': 'Aestheticization of politics" ?  "Politicization of aesthetics"?',
      'hp-lesson5-title': ' Collectivism',
      'hp-lesson5-description': 'Life itself as the object of sovereignty',
      'hp-lesson6-title': 'Psychoanalysis in Literature',
      'hp-lesson6-description': 'Diving into the world of the collective unconscious',
      'hp-lesson7-title': 'Technological Alienation',
      'hp-lesson7-description': 'Science≠Scientific applications≠Technology',
      'hp-lesson8-title': 'Discipline and Punishment',
      'hp-lesson8-description': 'We all live in an invisible panopticon',
      //----------About us----------
      'au-page-title':'Know More About \'illiterate\'',
      'au-page-title2':'Why Are We \'illiterate\'?',
      'au-page-title3':'The First “illiterate”s: Our Founders',
      'au-description1': 'The initiative behind the formation of "illiterate" book club originates our own personal experience: the rhythm and the abundance of information of the internet often leads to multiple negative effects, such as a decrease in reading comprehension skills and a lack of critical thinking, which in turn results in the inability to engage in effective intellectual discourse. In other words, we are trapped in an "information bubble", where everything seems to be absolute and our function is simply to receive infinite monotonous data. This might betray our lives as rational human beings.Thus, our goal is to provide a platform where people may contemplate and communicate different ideas, creating discussions that aims to bring a sense of rationality to our lives.',
      'au-description2': 'The word "illiterate" is defined as one who doesn\'t know. This is inspired by a quote from the famous philosopher Socrates, who famously said "I know that I know nothing"; once we obtain this mindset of open-mindedness, we may commence the pursuit of knowledge. We hope that through discussions, others may recognize that their views might not be correct, and hence becomes receptive to other opinions.',
      'au-description3': 'The book club "illiterate" was established by Lily from YK Pao School and Maggie from SSES, while Fiona and Louis joined later during the first season. We provide two types of memberships: permanent, which includes mostly secondary school students from SSES, and non-permanent for others who might be interested and might not want to be pressurized by long-term participation',
      'au-description4': 'We believe that discourse is a core aspect of our lessons; we will always reserve 10-20 minitues for participants to question the presenter, while also engaging in discussions with other members.',
      //----------IUtopian ----------
      'iu-t1-description1': '"Living in such a fast-paced society, we are losing our ability for critical reflection to our hectic "schedules". What kind of society are we actually living in? Is it the same as we thought it would be when we were young? Except for occasional moments of bliss, we may be largely dissatisfied with the status quo, or perhaps exhausted by it, but those retrospective fragments are forever lost in the abyss of reality, forcing us to continue with our monotonous schedules. Dystopian novels frequently paint a sarcastic and exaggerated worldview, leading us to be thankful for the present.',
      'iu-t1-description2': 'However, is modern society actually detached from those fictional dystopias? Is there truly nothing in the status quo that resembles the bleakness of Oceania? Are we perhaps the shadows of Winston and Julia, which are constantly under the control of Big Brother?',
      'iu-t1-description3': 'From the drama of such fictional scenarios to reality, it is our duty to be more sensitive to our surroundings."',
      'iu-t1-title': 'Introduction for Theme "Myths from Utopia"',
      //--iu-1
      'iu-lesson1-title': 'Introduction to dystopian novels',
      'iu-lesson1-theme': 'First investigate the following questions: Where are the utopias? What are they like? What happens in a utopia?, then dive deep into the "myths of utopia"',
      'iu-lesson1-content': 'For this lesson, we started with explaining the etymology of the word "utopia", and introduced several renowned examples of both utopian and dystopian novels: "The Giver", "The Hunger Games" and “There Will Come Soft Rains". Through general literary analyses along with specific analyses of selected text, we hope that members may become more familiar with the structure and contents of dystopian novels, hence being able to better understand Orwell\'s "1984".',
      //--iu-2
      'iu-lesson2-title': 'Background of Orwell\'s "1984"',
      'iu-lesson2-theme': '  As "1984" is essentially the magnum opus of George Orwell, we hope to approach the book by starting with the contexts of the author and extending it to the scope of the novel itself. Our aim is to allow students to form more comprehensive interpretation of the themes of the book, clarifying the slightly more confusing parts of the work.',
      'iu-lesson2-content': '  Through a brief on the intentions of authors, we commence with an introduction of the author of "1984", George Orwell. His experience during wartimes strongly impacted his style of writing along with his political perspective, causing him to often propose equality, debate, and freedom while opposing socialism.',
      //--iu-3
      'iu-lesson3-title': 'Introduction to Research',
      'iu-lesson3-theme': ' In order to depthen our throughts, we have to take the opinions of experts and academics into consideration. The ability to do independent research is not only key in the development of mature thoughts, but also essential for school and university. How do we efficiently find papers that are related to our field of interest? How should we read them? Those are all important questions to ask. In this lesson, the organizers shared their own personal experience as a researcher, aiming to establish necessary groundworks for students.',
      //--iu-4
      'iu-lesson4-title': 'Using art as means of political advertisement',
      'iu-lesson4-theme': 'The horror of politics lies in its corruptive nature; it is a self-expanding entity, trying to posit everything as means to itself. An apparent example of this phenomenon is its effect on arts and aesthetics. When art becomes a method for mass indoctrination, is it still considered art? Under such a situation, can we still spot the absurdity of art itself?',
      'iu-lesson4-content': 'We started this lesson by introducing Walter Benjamin\'s thoughts in "The Work of Art in the Age of Mechanical Reproduction". Through that, we discussed the standardization of commercial art (such as films) and their importance in modern society. We also considered Neil Postman\'s work "Amusing Ourselves to Death", which acts as a warning to the mass transformation of art into entertainment along with an elucidation of the conflict between business incentives and the nature of art. Such a conflict results in the alienation of art, deeming it more and more absurd and meaningless. On this occasion, politics takes advantage and frames art as a way for effective indoctrination, alienating them to be mere propaganda; an evidence supporting the corruptive imperative of politics.',
      //--iu-5
      'iu-lesson5-title': ' Collectivism',
      'iu-lesson5-theme': '  Collectivism is a theme of common reoccurence in dystopian novels. In such a world, the people are no longer viewed as people, instead, they are simply existing for the welfare of the state and the benefits of the "population". This finally leads to the elimination of the individual, which also stimulates a never-ending desire of authority of the state. The conclusion is bleak: the state doesn\'t care about who the ruler is, at least it will always exist, eternally.',
      'iu-lesson5-content': ' In this lesson, we introduced Giorgio Agamben\'s theory on biopolitics, exploring the origins of "power" and "bare life" along with their representations in "1984". We also observed several real-life examples: historically, the Nazi concentration camp; currently, lockdown and our subsequent dismantling of rights and freedoms in post-pandemic era.',
      //--iu-6
      'iu-lesson6-title': 'Psychoanalysis in literature',
      'iu-lesson6-theme': 'Using "1984" as a medium, we aim to introduce Freud\'s psychoanalysis as a novel method for psychological literary analysis, which provides us with a multi-faceted understanding of the book. We also aim to encourage students to implement such a method to other readings. ',
      'iu-lesson6-content': 'We commenced this lesson by introducing Austrian psychologist Sigmund Freud, who pioneered and established psychoanalysis. We then continued to present such a methodology by considering the "Iceberg Theory", in which we came in contact with the concept of consciousness. Through exploring the Id, the Ego, and the Superego, we also tried to analyze selected texts in the perspective of Winston in "1984" whether by analyzing the unconscious mind of the character and the author through the narrator\'s language, or by exploring and dissecting the dreams of others.',
      //--iu-7
      'iu-lesson7-title': 'Technological Alienation',
      'iu-lesson7-theme': 'Through the experiments of scientists along with implementations in the book "1984", we extend to the broader comparison of science, applied science, and technology, along with their politicization. We also introduced the concept of technical rationality, in which we aim to inspire others to think about the roles of consumerism in real life.',
      'iu-lesson7-content': 'We beginned the lesson by introducing Pavlov\'s and Pötzel\'s experiments on animals and their reactions to physical and psychological stimuli, in which we then gave examples where such results were used in the book "1984", adding a sense of sarcasm to its plot. Through that, we extend to the overall politicization and capitalizationof the broad science, where we attempted to differentiate (theoretical) science, applied science and technology, discussing how each are affected differently by consumerism and politics. To further enrich our understanding of it, we considered the concept of technical (technological) rationality through Herbert Marcuse\'s "One-dimensional Man"; through that, we discussed the effects of technological alienation and their assimilation of our minds.',
      //--iu-8
      'iu-lesson8-title': 'DisciplineandPunish',
      'iu-lesson8-theme': 'We\'ve discovered that through a multitude of dystopian novels sovereigns will often establish punishments and regulations that consolidate their position as ultimate authorities and also maintain the ideological and material unity of the people. Thus, to ensure that students have a fair grasp of the sovereign\'s methods for indoctrinating and controlling the general public in "1984" and other dystopian novels, we considered Michel Foucault\'s "Discipline and Punish" and such concepts within. Furthermore, we hope that students may use such concepts to scrutinize the critically reflect on the realistic representations of "discipline" and "punishments".',
      'iu-lesson8-content': 'To facilitate our thorough investigation of Foucault\'s work, we divided the tour lesson into two sections: "discipline" and "punish". We also attempted to extend on the aforementioned concept of "anonymous authority" as presented in the thematic analysis of "1984" in lesson 1. Firstly, we compared Foucault\'s conception of the effect of violence with the society of "1984". We then introduce the forms of discipline through Foucault\'s interpretation of "discipline", while also acknowledging the panopticon as derived from the view that we all live in such a prison. As we provide a synopsis of Foucault\'s general theories, we extend on the concept of "anonymous authority" by Fromm as appeared in our first lesson. Lastly, we urged students to reflect upon the contents of "1984" through the concepts mentioned in "discipline and punish", but also to bring them back to reality and investigate how such a system is used in our daily lives.',
      //--portage
      'iu-debate-title': 'Concluding Debate',
      'iu-debate-description': 'For individual interests, people should submit some of their rights to the sovereignty',
      'iu-debate-Prop-title': 'Prop.',
      'iu-debate-Prop-point1': 'Social contract theory (Hobbes, Rousseau, Locke)',
      'iu-debate-Prop-point2': 'Seperation of powers (Montesquieu)',
      'iu-debate-Prop-point3': 'Market failure',
      'iu-debate-Prop-point4': 'Discipline and Punish (Foucault)',
      'iu-debate-Oppo-title': 'Oppo.',
      'iu-debate-Oppo-point1': 'Conflict of duties in unethical governments (Arendt)',
      'iu-debate-Oppo-point2': 'Anarchism',
      'iu-debate-Oppo-point3': 'Biopolitics and bare life (Agamben)',
      //----------Comments----------
      'pc-comments1-comment': 'When I first entered Illiterate I had only the mindset to enhance my reading habits; the first few sessions were about the interpretation of utopian novels and some analytical perspectives, many of which I had not considered before. And as the course progressed, I started to learn to read literature and understand many theories related to philosophy. Even though it\'s online, you\'ve been able to balance fun and academic learning, fantastic!',
      'pc-comments2-comment': 'Over the course of the season, the illiterate community conducted workshops around various concepts in the discipline of politics. Of the several sessions I participated in, the two on how Orwell\'s famous novel 1984 serves its political purposes and the connection between life politics and collectivism were more of my likings. Orwell is a writer I like very much, and I have read through 1984 more than once, but some of the perspectives presented in the workshop were ones I had never thought of before. I can say that during the exchange, other students\' ideas greatly broadened my horizons. Another thing I thought illiterate did well was the discussion questions that came up at the end of each session. These questions were very helpful in uncovering new perspectives on thinking and helping the younger students master critical thinking. Unfortunately, the final debate did not have the desired effect, but I believe that after this experience, illiterate will get better and better.',
      'pc-comments3-comment': 'In these lessons, we analyze critical theories by using dystopian novels as mediums, ensuring a deeper understanding of the book. We also often scruitinize and criticize such theories, finding loopholes within. From the word utopia, its simple meaning extends to its multifaceted nature by considering other theories that explains its structure, such as the social contract theory etc. My understanding of the word utopia, after several lessons, is that these three words stand for "ideal", "hope", and "collective", so utopia in general means an imaginary state, a group. The reason why it is ideal is that it is almost impossible to achieve such a beautiful and absolutely peaceful world. The PPT and the presentation were both comprehensive and informative. In particular, the PowerPoint was very logical, and I was impressed by the picture of "Big Brother is looking at you" in lesson 4, because it appeared at the beginning, after the analysis, and again after the concept was explained.',
      'pc-comments4-comment': 'After joining the illiterate book club, we explored and discussed the contents of the book from various perspectives, including artistic techniques and psychology, through reading classic books such as "1984" and under various activities of group reflection. In the process, in-class discussion questions and debates facilitated us to think differently and critically about issues from multiple perspectives. The handouts and video recordings sent after each class were a great way to assist us in absorbing and going deeper into our knowledge. Looking forward to the next season!',
      'pc-comments5-comment': 'I got into this project because I was interested in the theme of the season (dystopian literature). In this day and age, we like to have quick access to information, and the opportunity to spend a few hours to seriously learn and understand something is becoming rare. Such opportunities are later found to be meaningful only after my experience in Illiterate. In addition to getting a lot of information from the book, it was interesting to see how it is reflected in reality, whether it is government, literary techniques, psychology, or social issues, etc. These things, which we don\'t usually get to know, were shared among peers who were discussing the issues together and generating new perspectives. If there are similar activities in the future, I will definitely support them!',
      //----------IIEchoOfPain----------
      'iiep-page-title':'New "Illiterate"s',
      'iiep-cs-description': 'Existence is a balance of realism and idealism, frequently falling into perpetual self-doubt. Idealization is my way of escaping from reality.',
      'iiep-jy-description': 'A bewildered teenager trying to seek knowledge from conflicting opinions. Suddenly the realization of how dumb the naive, outgoing boy I was, I turned into a taciturn teenager in high school. I hope that through the tragedy of the world around us, everyone can realize our ignorant and find the meaning of life.'
    }
  },
  ch: {
    translation: {
      //Navbar
      'nav-hp': '首页',
      'nav-au': '关于我们',
      'nav-iut': 'I 乌托邦的迷思',
      'nav-cyn': 'CYNTICS',
      'nav-pc': '参与者反馈',
      'nav-iep': 'II 苦难的回声',
      //----------HomePage----------
      'hp-lesson-title':'Season #1 : 乌托邦的迷思',
      'hp-lesson1-title': '反乌托邦小说入门',
      'hp-lesson1-description': '从乌托邦小说的起源说起',
      'hp-lesson2-title': '《1984》创作背景',
      'hp-lesson2-description': '乔治·奥威尔：我为何写作',
      'hp-lesson3-title': '我们如何检索文献？',
      'hp-lesson3-description': '到底要怎么做research？',
      'hp-lesson4-title': '将艺术作为政治宣传的手段',
      'hp-lesson4-description': '政治美学化？美学政治化？',
      'hp-lesson5-title': '集体主义',
      'hp-lesson5-description': '生命本身 就是主权权力的载体',
      'hp-lesson6-title': '文学中的心理分析',
      'hp-lesson6-description': '潜入集体潜意识',
      'hp-lesson7-title': '科技异化',
      'hp-lesson7-description': '科学≠应用科学≠科技',
      'hp-lesson8-title': '规训与惩罚',
      'hp-lesson8-description': '我们都生活在全景敞视监狱中',
      //----------About us----------
      'au-page-title':'“不识”的理念',
      'au-page-title2':'为何“不识”？',
      'au-page-title3':'最初的“不识”者',
      'au-description1': '“成立读书会”的想法来自于我们亲身的体会：快节奏网络时代对人们阅读和思考造成了种种负面影响，让我们难以进行深刻地思考，与他人促膝长谈变成了一件难之又难的事；我们被束缚在“信息茧房”中，只看见非黑即白的世界。我们希望读书会能成为大家重新静下心思考，交流意见、思想碰撞的平台，为浮躁的当下增添一抹理性的从容。',
      'au-description2': '“不识”读书会最开始是由来自ykpao的lily和来自sses的maggie创建的，在第一期的探索过程中也有幸与志同道合的伙伴fiona和louis相遇。我们的受众大部分人来自sses的myp和dp，并且分为固定席位和流动席位。固定席位是给那些长期参加读书会的同学，而流动席位则是课前一天进行召集，针对那些对本节课感兴趣但是又不想感到长期上课压力的同学。',
      'au-description3': 'illiterate意味无知的人，苏格拉底曾说：“认识自己的无知就是最大的智慧。”我们希望大家在交流的过程中能够意识到自己的看法并不是绝对正确的，接触到多元的思想。我们其实都是“无知”的人，终其一生都在漫漫长路上下求索。',
      'au-description4': '我们的讨论会以授课为主，而在每节课的最后我们都会预留10-20分钟给参与者们针对组织者今天所分享的内容，以及思考题中不同角度的观点和想法进行开放讨论。',
      //----------IUtopian ----------
      'iu-t1-description1': '生活在这个快节奏的社会，我们似乎总是忙碌，却失去了主动反思的能力。我们究竟生活在一个什么样的社会中？这个社会与我们更年少所想象的那个社会一样吗？除了时常发现生活的小确幸，也许有时我们也会对自己所处的环境有些许不满，有些许无奈，有些许想对它说的话，但它们最终都被倾泻而来的琐事带走，尘封于再也见不到光的某个角落。',
      'iu-t1-description2': '反乌托邦小说是讽刺的，是夸张的，我们看完后会庆幸自己并不处于这样的一个社会当中。但是是否在某些被我们忽视的地方，也有大洋国的影子呢？我们的内心中是否也藏着一个个温斯顿与朱丽亚，我们是否也被“老大哥”悄悄凝视着而不自知呢？',
      'iu-t1-description3': '从戏剧性的悲剧出发回到看似平淡的现实，我们也对我们正所经历着的生活更加敏感，陷入思考。',
      'iu-t1-title': '第一期“乌托邦的迷思”主题介绍',
      //--iu-1
      'iu-lesson1-title': '反乌托邦小说入门',
      'iu-lesson1-theme': '要想潜入“乌托邦的迷思”，先得明晰“乌托邦”到底在哪：它到底是什么样的地方，发生了哪些故事。',
      'iu-lesson1-content': '本节课，我们从“乌托邦”的词源出发，介绍了几部较为经典的“乌托邦”和“反乌托邦”小说：《记忆传授人》、《饥饿游戏》、《细雨将至》。通过对它们主题的解析与部分选文的细读，我们希望大家对这类小说增加一定的了解与认知，更好地理解《1984》的内容与形式。',
      //--iu-2
      'iu-lesson2-title': '《1984》创作背景',
      'iu-lesson2-theme': '因为乔治·奥威尔作为本期主要作品《1984》的作者，所以我们希望通过“知人论世”的方法，让同学们知道奥威尔所处时代、经历、阶级等，从而更好的理解他作品的中心思想，《1984》这本书中一些较为晦涩的内容也就迎刃而解了。',
      'iu-lesson2-content': '本节课我们通过作家的普遍写作意图，从而介绍了《1984》的作者，英国著名小说家、记者、社会评论家乔治·奥威尔的生平经历。奥威尔身处战争中的经历对其作品从语言风格和政治主张两方面产生了影响，让他的作品中提倡人们追求平等和争议，自由且消除平困的社会主义。',
      //--iu-3
      'iu-lesson3-title': '我们如何检索文献？',
      'iu-lesson3-theme': '要想深入的思考，对于专家们意见的参考也是必不可少的，而在未来大家也会阅读大量的学生论文。该如何高效寻找和自己研究问题相关的论文，又该如何阅读它们变成了十分重要的问题。本节课中，我们为大家介绍了我们作为过来人的经验，为大家之后的会经历的各种research砌上了第一块砖。',
      //--iu-4
      'iu-lesson4-title': '将艺术作为政治宣传的手段',
      'iu-lesson4-theme': '政治的可怕之处在于其贪婪，它在自身不断膨胀的过程中，不可避免地同化其他事物，美学与艺术也难逃一劫。当艺术成为洗脑的另一种手段，它是否还能被称为艺术？或者说到那种境况下，我们还能意识到艺术本身的空洞吗？',
      'iu-lesson4-content': '在本节课中我们将引入本雅明《机械复制时代的艺术作品》的哲学思考，探讨电影等同质化的商业艺术在现当代社会中所扮演的重要角色。我们也将借用尼尔·波兹曼在《娱乐至死》对于现代社会泛娱乐化的警示，观察艺术商业化后与其本身存在价值的冲突。这种冲突使得艺术本身意义的空洞，而此时政治便乘虚而入。而处于这种我们也会以宣传画为例，观察这种作为典型宣传工具内容的同质化是如何将自己剥离出艺术的范畴。',
      //--iu-5
      'iu-lesson5-title': '集体主义',
      'iu-lesson5-theme': '集体主义是反乌托邦小说中常常反复出现的一大议题，人民往往不再被视作人民，而是为了某种高于自身的集体身份/国家而服务。然而，这往往造成了群众的去人格化，同时将国家变成了某种一旦启动了就无法再停下的机器。国家本身不再在意谁当权，因为它将会永垂不朽。',
      'iu-lesson5-content': '在本节课中，我们将引入阿甘本的生命政治，探究主权和赤裸生命是如何产生的，以及其在《1984》政权中的体现。在后疫情时代，我们并不难发现现实中阿甘本理论的投射：“集中营是一个应急状态出现就会被打开的区域”，思考我们该如何面对自己悄然间所剩无几的尊严与自由。',
      //--iu-6
      'iu-lesson6-title': '文学中的心理分析',
      'iu-lesson6-theme': '《1984》作为本期的主文本，我们希望从心理学的方面介绍给同学们如何用弗洛伊德的精神分析法，这种新颖的文学分析方式，通过旁白和叙述去分析作者的潜意识，从而了解作者写作主题，通过梦境/话语去剖析人物性格和经历，所以在阅读不同的文本时，会有更多方式去对其进行解读。',
      'iu-lesson6-content': '本节课中，我们介绍了奥地利心理学家、精神分析学派创始人西格蒙德·弗洛伊德。其次，我们从冰山理论，引出精神意识的概念。并且，我们介绍了弗洛伊德所开创的精神分析学，在本我、自我、超我所组成的人格介绍的基础上，将其运用在《1984》主人公的分析上。',
      //--iu-7
      'iu-lesson7-title': '科技异化',
      'iu-lesson7-theme': '科学、应用科学与科技并不等同，而它们也并不如我们所想的那样百利而无一害。通过异化的科技，这些知识性的权力反而成为了维护意识形态权威的工具。不再是人类主宰科学，而是科学统治人类。',
      'iu-lesson7-content': '本节课我们将从巴甫洛夫、博伊泽尔的实验出发，探讨纯科学与应用科学、科技的不同。在了解了实验的具体过程与结论后，我们带领大家在分析科学如何在《1984》情节中反复出现，使得整本书更加讽刺与真实。我们将会引入马尔库塞在《单向度的人》中提出的“技术理性”的概念，探讨科技被异化后所对我们意识形态所产生的同质化效果。',
      //--iu-8
      'iu-lesson8-title': '规训与惩罚',
      'iu-lesson8-theme': '我们发现在许多反乌托邦小说所营造的社会环境中，权威们通常会用到制定规则和惩罚登来巩固地位和统一人民的行为和思想。于是，我们想到通过福柯在《规训与惩戒》的理念，来帮助同学们理解在《1984》等反乌托邦小说中主权者所用到巩固地位和权力的方式以及其作用。同时，我们也希望同学们可以带有批判性思考地看待生活中的规训和惩罚，所给社会带来的好处。',
      'iu-lesson8-content': '本节课，我们根据福柯的《规训与惩罚》这本书，将内容分成了“规训”与“惩罚”，以及对我们之前在第一节课《1984》主题分析中所提到的“匿名权威”进行了补充。首先，我们对比了福柯书中和《1984》社会中历史上暴力景观的作用；其次，我们通过福柯对“规训”的解读，向同学们介绍了规训的模式，并且还介绍了“全景敞视主义”所衍生的“全景敞视监狱”；在解读完福柯的理念后，我们重新给同学们铺垫了匿名权威的概念，最后，同学们思考了以上概念在《1984》中，是如何被运用和有所体现的，也让同学们联系现实，思考规训在生活中的利与弊。',
      //--portage
      'iu-debate-title': '结课辩论 ',
      'iu-debate-description': '为了自身利益的保护，人们应该上交一部分权利给主权',
      'iu-debate-Prop-title': '正方',
      'iu-debate-Prop-point1': '社会契约论（霍布斯、卢梭、洛克）',
      'iu-debate-Prop-point2': '三权分立（孟德斯鸠）',
      'iu-debate-Prop-point3': '市场失灵 ',
      'iu-debate-Prop-point4': '规训与惩罚 （福柯）',
      'iu-debate-Oppo-title': '反方',
      'iu-debate-Oppo-point1': '不道德政府中的责任困境 （阿伦特）',
      'iu-debate-Oppo-point2': '无政府主义',
      'iu-debate-Oppo-point3': '生命政治与赤裸生命 （阿甘本）',
      //----------Comments----------
      'pc-comments1-comment': '最初加入illiterate只是抱着督促自己读书的心态，前几期是关于乌托邦小说的解读以及一些分析角度，很多都是我之前没有考虑的点。并且随着课程的深入，开始学习阅读文献，也了解很多哲学相关的理论。尽管是线上授课，但是你们一直巧妙的融合了趣味性与学术性，棒棒！',
      'pc-comments2-comment': '在这一学期中，illiterate社团围绕着各类政治学科中的概念开展研讨会。在我参与的几次会议中，关于奥威尔著名小说《1984》如何服务于其政治目的，生命政治和集体主义关联的两次印象比较深刻。奥威尔是我非常喜欢的作家，而《1984》我也不止通读过一次，但在研讨会中所提出的一些角度是我之前从未想到过的。可以说在交流的过程中，同学们的想法大大拓宽了我的视野。另一点我认为illiterate做的很好的是每次在最后出现的讨论问题，这些问题对于发掘新的思维角度，帮助低年级同学掌握批判性思考是十分有帮助的。可惜的是最后的辩论没有达到预期中的效果，但是相信经过这次经验，illiterate会越变越好。',
      'pc-comments3-comment': '在这几节课当中，我们从几本乌托邦小说作为基础进行分析里面的理论。使对书有更加深刻的理解，并且全面。甚至可以推翻书中的理论，发现出理论的漏洞。从乌托邦这个简单的词语，简单的意思到它的多面性，以及可以支撑他的许多理论。如社会契约论等等。我对乌托邦这个词语的理解在几节课的分析之后，我认为是这三个字分别代表“没有” “寄托希望的” “集体”，因此乌托邦连在一起就是空想的国家，团体。之所以是空有的是因为几乎没有可能实现这样美好绝对和平的世界。 PPT和讲解都是比较全面和丰富的。尤其是ppt非常有逻辑感，其中有一节中那张“老大哥正在看着你”的那张照片我很有印象，因为他在开头出现在分析过后，又一次在讲完概念后出现，我认为本意就是为了能让我们这些受众在听过分析之后对这张图有新的理解。',
      'pc-comments4-comment': '加入illiterate读书会后，通过阅读《1984》等经典书籍和集体思考的多种活动下，我们从艺术手法、心理学等多方面角度去探究和讨论了书中的内容。在此过程中，课上的讨论题和辩论促进了我们发散思考，批判性地从多个角度思考问题。每节课后发来的讲义和视频录制都能很好的辅助我们去吸收和深入知识。期待下一期的课堂！',
      'pc-comments5-comment': '一开始进入这个项目是觉得这个活动的主题很感兴趣（反乌托邦文学），尤其是《1984》本身就是让我印象很深刻的书。而在当代，我们会喜欢一些很快的信息渠道，那些花几个小时认真去了解和理解一些事物的机会又变得少之又少。这样的活动在体验之后才发现很有意义。除了能从书之外获得很多它在现实当中折射的事物，无论是政府、文学的技巧、心理学、以及很多社会问题是如何映射的etc.。而这些平时不（很）怎（懒）么去了解的事物，却能在一些一起探讨问题的同龄人之间进行交流，并产生新的认知角度。如果以后还有类似的活动肯定会大力支持！',
        //----------IIEchoOfPain----------
      'iiep-page-title':'新的“不识”者',
        'iiep-cs-description': '想在某一刻飞走的现实物体，变成无限内耗永动机是我摆烂的一种。你或许可以在理想和现实的交接坐标上找到我，找不到的时候可能会在海里抓蝴蝶。',
        'iiep-jy-description': '一个企图在矛盾的思想中找到知识的迷茫者. 在中学中突然意识到以前的话又多有不做事考虑后果的自己多么愚蠢，莫名转变了一个有点怕生的寡言者。希望我们都能在悲剧的世界认识到自己的无知,找到生活的意义和追寻的方向。'
    }
  }
};


i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
      // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
      // if you're using a language detector, do not define the lng option

      interpolation: {
        escapeValue: false // react already safes from xss
      },
      react: {
        transSupportBasicHtmlNodes: true
      }
    });


console.log('init language')
i18n.languages = ['en', 'ch'];

export default i18n;