import React from 'react';
import './IIEcho.css'
import avatar1 from '../assets/IE/corrine.jpg'
import avatar2 from '../assets/IE/jerry.jpg'
import {useTranslation} from "react-i18next";



const IiEchoOfPain = () => {

  const {t} = useTranslation();
  return (
      <div>
        <div className={'p-2 p-lg-5 ie-title text-center'} >
            <div>
              <p className={'d-inline'}>#2 New Theme:</p>
              <div className={'iiep-space d-inline-block'}></div>
              <br/>
              <div className={'iiep-space d-inline-block '}></div>
              <p className={'d-inline'}> Echo of Pain</p>
            </div>
        </div>

        <div className={'py-5 container text-start'}>
          <h1 className={'text-center'}>{t('iiep-page-title')}</h1>
          <div className={'row'}>
            <div className="col-lg-6 col-12 p-5">
              <div className="card rounded-0" style={{backgroundColor:'#ced1d7'}}>
                <img src={avatar1} className="card-img-top" alt="..."/>
                <div className="card-body ">

                  <div className="card-title mb-4">
                    <p className={'m-0'}>Idealization</p>
                    <h4>Corrine Su</h4>
                  </div>
                  <p className="card-text">
                    {t('iiep-cs-description')}
                  </p>
                </div>
              </div>
            </div>


            <div className="col-lg-6 col-12 p-5">
              <div className="card  rounded-0"  style={{backgroundColor:'#ced1d7'}}>
                <img src={avatar2} className="card-img-top" alt="..."/>
                <div className="card-body" >

                  <div className="card-title mb-4">
                    <p className={'m-0'}>
                      A bewildered teenager</p>
                    <h4>Jerry Ying</h4>
                  </div>
                  <p className="card-text">
                    {t('iiep-jy-description')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default IiEchoOfPain;