import React from 'react';
import './PC.css'
import {useTranslation} from "react-i18next";

const ParticipantsComment = () => {

  const {t} = useTranslation()

  return (
      <div>
        <div style={{backgroundColor: '#1b1a21'}} id="carouselExampleCaptions" className="carousel  slide carousel-fade" data-bs-ride="false">
          <div className="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active"
                    aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"
                    aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"
                    aria-label="Slide 3"></button>
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3"
                    aria-label="Slide 4"></button>
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="4"
                    aria-label="Slide 5"></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className=" d-block w-100 pc-img"/>
              <div className="carousel-caption h-100 ">
                <div className={'h-100 d-flex align-items-center flex-column justify-content-center'}>
                  <h5>
                    <svg preserveAspectRatio="xMidYMid meet" data-bbox="13.577 30.993 172.003 140"
                         viewBox="13.577 30.993 172.003 140" height="40" width="200" xmlns="http://www.w3.org/2000/svg"
                         data-name="Layer 1" data-type="color" role="presentation" aria-hidden="true"
                         aria-labelledby="svgcid-13cbflr0mqjh">
                      <defs>
                        <style>#comp-l89m6nan2 svg [data-color="1"]</style>
                      </defs>
                      <title id="svgcid-13cbflr0mqjh"></title>
                      <g>
                        <path fill="#bababc"
                              d="M86.586 49.574c-19.8 13.01-37.742 37.789-39.6 57.614.62 0 4.949-.62 8.042-.62 17.326 0 29.7 13.006 29.7 31.591a32.635 32.635 0 0 1-32.793 32.834c-20.416 0-38.358-16.727-38.358-47.081 0-37.789 21.654-72.477 50.116-92.919zm98.994 0c-19.8 13.01-37.123 37.789-39.6 57.614.616 0 4.948-.62 8.042-.62 17.322 0 30.316 13.006 30.316 31.591 0 17.964-14.848 32.834-33.41 32.834-20.416 0-38.358-16.727-38.358-47.081 0-37.789 21.652-72.477 50.113-92.919z"
                              data-color="1"></path>
                      </g>
                    </svg>
                  </h5>
                  <p className={'pc-caption'}>
                    {t('pc-comments1-comment')}
                  </p>
                  <br/>
                  <p>Linda Pan</p>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className=" d-block w-100 pc-img bg-grey" style={{backgroundColor:'#757575'}}/>
              <div className="carousel-caption h-100 ">
                <div className={'h-100 d-flex align-items-center flex-column justify-content-center'}>
                  <h5>
                    <svg preserveAspectRatio="xMidYMid meet" data-bbox="13.577 30.993 172.003 140"
                         viewBox="13.577 30.993 172.003 140" height="40" width="200" xmlns="http://www.w3.org/2000/svg"
                         data-name="Layer 1" data-type="color" role="presentation" aria-hidden="true"
                         aria-labelledby="svgcid-13cbflr0mqjh">
                      <defs>
                        <style>#comp-l89m6nan2 svg [data-color="1"]</style>
                      </defs>
                      <title id="svgcid-13cbflr0mqjh"></title>
                      <g>
                        <path fill="#bababc"
                              d="M86.586 49.574c-19.8 13.01-37.742 37.789-39.6 57.614.62 0 4.949-.62 8.042-.62 17.326 0 29.7 13.006 29.7 31.591a32.635 32.635 0 0 1-32.793 32.834c-20.416 0-38.358-16.727-38.358-47.081 0-37.789 21.654-72.477 50.116-92.919zm98.994 0c-19.8 13.01-37.123 37.789-39.6 57.614.616 0 4.948-.62 8.042-.62 17.322 0 30.316 13.006 30.316 31.591 0 17.964-14.848 32.834-33.41 32.834-20.416 0-38.358-16.727-38.358-47.081 0-37.789 21.652-72.477 50.113-92.919z"
                              data-color="1"></path>
                      </g>
                    </svg>
                  </h5>
                  <p className={'pc-caption'}>
                    {t('pc-comments2-comment')}
                  </p>
                  <br/>
                  <p>Steven Chen</p>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className=" d-block w-100 pc-img"/>
              <div className="carousel-caption h-100 ">
                <div className={'h-100 d-flex align-items-center flex-column justify-content-center'}>
                  <h5>
                    <svg preserveAspectRatio="xMidYMid meet" data-bbox="13.577 30.993 172.003 140"
                         viewBox="13.577 30.993 172.003 140" height="40" width="200" xmlns="http://www.w3.org/2000/svg"
                         data-name="Layer 1" data-type="color" role="presentation" aria-hidden="true"
                         aria-labelledby="svgcid-13cbflr0mqjh">
                      <defs>
                        <style>#comp-l89m6nan2 svg [data-color="1"]</style>
                      </defs>
                      <title id="svgcid-13cbflr0mqjh"></title>
                      <g>
                        <path fill="#bababc"
                              d="M86.586 49.574c-19.8 13.01-37.742 37.789-39.6 57.614.62 0 4.949-.62 8.042-.62 17.326 0 29.7 13.006 29.7 31.591a32.635 32.635 0 0 1-32.793 32.834c-20.416 0-38.358-16.727-38.358-47.081 0-37.789 21.654-72.477 50.116-92.919zm98.994 0c-19.8 13.01-37.123 37.789-39.6 57.614.616 0 4.948-.62 8.042-.62 17.322 0 30.316 13.006 30.316 31.591 0 17.964-14.848 32.834-33.41 32.834-20.416 0-38.358-16.727-38.358-47.081 0-37.789 21.652-72.477 50.113-92.919z"
                              data-color="1"></path>
                      </g>
                    </svg>
                  </h5>
                  <p className={'pc-caption'}>
                    {t('pc-comments3-comment')}
                  </p>
                  <br/>
                  <p>Cloris Chen</p>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className=" d-block w-100 pc-img" style={{backgroundColor:'#757575'}}/>
              <div className="carousel-caption h-100 ">
                <div className={'h-100 d-flex align-items-center flex-column justify-content-center'}>
                  <h5>
                    <svg preserveAspectRatio="xMidYMid meet" data-bbox="13.577 30.993 172.003 140"
                         viewBox="13.577 30.993 172.003 140" height="40" width="200" xmlns="http://www.w3.org/2000/svg"
                         data-name="Layer 1" data-type="color" role="presentation" aria-hidden="true"
                         aria-labelledby="svgcid-13cbflr0mqjh">
                      <defs>
                        <style>#comp-l89m6nan2 svg [data-color="1"]</style>
                      </defs>
                      <title id="svgcid-13cbflr0mqjh"></title>
                      <g>
                        <path fill="#bababc"
                              d="M86.586 49.574c-19.8 13.01-37.742 37.789-39.6 57.614.62 0 4.949-.62 8.042-.62 17.326 0 29.7 13.006 29.7 31.591a32.635 32.635 0 0 1-32.793 32.834c-20.416 0-38.358-16.727-38.358-47.081 0-37.789 21.654-72.477 50.116-92.919zm98.994 0c-19.8 13.01-37.123 37.789-39.6 57.614.616 0 4.948-.62 8.042-.62 17.322 0 30.316 13.006 30.316 31.591 0 17.964-14.848 32.834-33.41 32.834-20.416 0-38.358-16.727-38.358-47.081 0-37.789 21.652-72.477 50.113-92.919z"
                              data-color="1"></path>
                      </g>
                    </svg>
                  </h5>
                  <p className={'pc-caption'}>
                    {t('pc-comments4-comment')}
                  </p>
                  <br/>
                  <p>Lilian Wang</p>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className=" d-block w-100 pc-img"/>
              <div className="carousel-caption h-100 ">
                <div className={'h-100 d-flex align-items-center flex-column justify-content-center'}>
                  <h5>
                    <svg preserveAspectRatio="xMidYMid meet" data-bbox="13.577 30.993 172.003 140"
                         viewBox="13.577 30.993 172.003 140" height="40" width="200" xmlns="http://www.w3.org/2000/svg"
                         data-name="Layer 1" data-type="color" role="presentation" aria-hidden="true"
                         aria-labelledby="svgcid-13cbflr0mqjh">
                      <defs>
                        <style>#comp-l89m6nan2 svg [data-color="1"]</style>
                      </defs>
                      <title id="svgcid-13cbflr0mqjh"></title>
                      <g>
                        <path fill="#bababc"
                              d="M86.586 49.574c-19.8 13.01-37.742 37.789-39.6 57.614.62 0 4.949-.62 8.042-.62 17.326 0 29.7 13.006 29.7 31.591a32.635 32.635 0 0 1-32.793 32.834c-20.416 0-38.358-16.727-38.358-47.081 0-37.789 21.654-72.477 50.116-92.919zm98.994 0c-19.8 13.01-37.123 37.789-39.6 57.614.616 0 4.948-.62 8.042-.62 17.322 0 30.316 13.006 30.316 31.591 0 17.964-14.848 32.834-33.41 32.834-20.416 0-38.358-16.727-38.358-47.081 0-37.789 21.652-72.477 50.113-92.919z"
                              data-color="1"></path>
                      </g>
                    </svg>
                  </h5>
                  <p className={'pc-caption'}>
                    {t('pc-comments5-comment')}
                  </p>
                  <br/>
                  <p>Chris Chen</p>
                </div>
              </div>
            </div>
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions"
                  data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions"
                  data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>

      </div>
  );
};

export default ParticipantsComment;