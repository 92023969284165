import React from 'react';
import './AboutUsPage.css'
import avatar1 from '../../assets/aboutUs/yqh.jpg'
import avatar2 from '../../assets/aboutUs/lyy.jpg'
import avatar3 from '../../assets/aboutUs/fiona.jpg'
import avatar4 from '../../assets/aboutUs/louis.jpg'
import {useTranslation} from "react-i18next";


const AboutUs = () => {

  const {t}= useTranslation();
  return (
      <div className={'aboutUs-container px-0  pb-0 pb-lg-5  '}>
        <div className={'text-lg-center text-start d-flex justify-content-center px-4 px-lg-0'}>
          <div className={'aboutUs-t1'}>
            <h1 className={'my-lg-5 my-3'}>{t('au-page-title')}</h1>
            <p className={'aboutUs-title-description '}>
              {t('au-description1')}
            </p>
          </div>
        </div>

        <div className={'row aboutUs-t2 m-0 pt-5'}>
          <div className={'col-lg-6 col-12 title d-flex align-items-center text-lg-center text-start px-4 px-lg-0'}>
            <p className={'flex-grow-1 m-0'}>
              {t('au-page-title2')}
            </p>
          </div>
          <div className={'col-lg-6 col-12 px-4 px-lg-0'}>
            <p className={'description'}>
              {t('au-description2')}

            </p>
          </div>
        </div>

        <div className={'aboutUs-t3 text-start d-flex justify-content-center px-4 px-lg-0 py-5'}>
          <div className={'aboutUs-t1'}>
            <h2 className={'text-uppercase my-lg-5'}>
              {t('au-page-title3')}
            </h2>
            <p>
              {t('au-description3')}
            </p>
          </div>

        </div>
        <div className={'aboutUs-t4 px-lg-5  p-0'}>
          <div style={{
            backgroundColor: '#ced1d7'
          }}
               className={'p-5'}>
            <div className={'row ju'}>
              <div className={'col-xxl-3 col-md-6 col-12 text-center'}>
                <img width={'269px'}
                     height={'323px'}
                     src={avatar1}/>
                <div className={'d-flex justify-content-end'}>
                  <div className={'avatar-name py-2 px-2'}>
                    <h3 className={'text-start'}>
                      Lily Yang
                    </h3>
                    <p className={'text-end small mb-0'}>
                      Master of loading.
                    </p>
                  </div>
                </div>

              </div>

              <div className={'col-xxl-3 col-md-6 col-12 text-center'}>
                <img width={'269px'} height={'323px'} src={avatar2}/>
                <div className={'d-flex justify-content-end'}>
                  <div className={'avatar-name py-2 px-2'}>
                    <h3 className={'text-start'}>
                      Maggie Li
                    </h3>
                    <p className={'text-end small mb-0'}>
                      Dire idealist
                    </p>
                  </div>
                </div>

              </div>

              <div className={'col-xxl-3 col-md-6 col-12 text-center'}>
                <img width={'269px'} height={'323px'} src={avatar3}/>
                <div className={'d-flex justify-content-end'}>
                  <div className={'avatar-name py-2 px-2'}>
                    <h3 className={'text-start'}>
                      Fiona Gu
                    </h3>
                    <p className={'text-end small mb-0'}>
                      Rebel, writer, activist
                    </p>
                  </div>
                </div>

              </div>

              <div className={'col-xxl-3 col-md-6 col-12 text-center'}>
                <img width={'269px'} height={'323px'} src={avatar4}/>
                <div className={'d-flex justify-content-end'}>
                  <div className={'avatar-name py-2 px-2'}>
                    <h3 className={'text-start'}>
                      Louis Chen
                    </h3>
                    <p className={'text-end small mb-0'}>
                      Embodiment of procrastination.
                    </p>
                  </div>
                </div>

              </div>


            </div>


          </div>
        </div>


        <div
            className={'text-lg-center text-center d-flex justify-content-center  px-4 px-lg-0 border border-top border-dark border-opacity-10 border-0 p-5'}
            style={{backgroundColor: '#ced1d7'}}>
          <div className={'aboutUs-t1 mt-3'}>
            <h1 className={'my-lg-2 my-3 '}>
              Form</h1>
            <p className={'aboutUs-title-description '}>
              {t('au-description4')}
            </p>
          </div>
        </div>


        {/*<div*/}
        {/*    className={'text-lg-center text-center d-flex justify-content-center  px-4 px-lg-0 border border-top border-dark border-opacity-10 border-0 p-5'}>*/}
        {/*  <div className={'mt-3 flex-grow-1'}>*/}
        {/*    <h3 className={'my-lg-2 my-3 '}>*/}
        {/*      Subscribe Form*/}
        {/*    </h3>*/}
        {/*    <div className={'d-flex justify-content-center'}>*/}
        {/*      <p className={'aboutUs-title-description d-flex flex-grow-1'} style={{maxWidth:'510px',height:'56px'}}>*/}

        {/*        <input type="email" className="form-control rounded-0" id="exampleFormControlInput1"*/}
        {/*               placeholder="e.g.,email@example.com"/>*/}
        {/*        <button className={'btn  btn-dark  rounded-0 px-4'}> Join</button>*/}
        {/*      </p>*/}
        {/*    </div>*/}

        {/*  </div>*/}
        {/*</div>*/}

      </div>
  );
};

export default AboutUs;