import React, {useState} from 'react';
import {Link, Outlet} from "react-router-dom";
// @ts-ignore
import logo from '../../assets/logo.png'
import {BlackColor} from "../../config";
import './home.css'
import {useTranslation} from "react-i18next";


const IndexPage = () => {

  const {t, i18n} = useTranslation();
  const [lang, setLanguage] = useState('en');
  return (
      <div className={'myNav'}>
        <nav className="navbar navbar-expand-lg navbar-dark "
        >
          <div className="container-fluid">
            <div className={'nav-container text-center'}>
              <div className={'nav-logo container '}>
                <div className={'d-flex flex-lg-column  justify-content-center flex-grow-1'}>
                  <img className={'logo-img col-6 col-lg-12 align-self-center mb-3 order-3'} src={logo}/>
                  <div className={'justify-content-center align-items-center justify-content-lg-end w-100 d-flex order-4 order-lg-0 '}>
                    <div>
                      <button type="button"
                              className="btn btn-secondary align-self-center   rounded-0 text-nowrap"
                              style={{backgroundColor: '#3f4549'}}
                              onClick={() => {
                                if (lang === 'en') {
                                  i18n.changeLanguage('ch')
                                  setLanguage('ch')
                                } else {
                                  i18n.changeLanguage('en')
                                  setLanguage('en')
                                }
                              }}
                      >
                        {lang === 'en' ? '中文' : 'ENG'}
                      </button>
                    </div>

                  </div>
                </div>

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>

              </div>
              <div className="collapse navbar-collapse  navContainer" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0 w-100 justify-content-center gap-3">
                  <li className="nav-item">
                    <Link to={'/Homepage'} className={'nav-link'}>{t('nav-hp')}</Link>
                  </li>
                  <li className="nav-item">
                    <Link to={'/AboutUs'} className={'nav-link'}>{t('nav-au')}</Link>
                  </li>
                  <li className="nav-item">
                    <Link to={'/IUtopianThoughts'} className={'nav-link'}>{t('nav-iut')}</Link>
                  </li>
                  <li className="nav-item">
                    <Link to={'/CYNTICS'} className={'nav-link'}>{t('nav-cyn')}</Link>
                  </li>
                  <li className="nav-item">
                    <Link to={'/ParticipantsComments'} className={'nav-link'}>{t('nav-pc')}</Link>
                  </li>
                  <li className="nav-item">
                    <Link to={'/IIEchoOfPain'} className={'nav-link'}>{t('nav-iep')}</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>

        <Outlet/>
        <div style={{height: '200px', fontSize: '14px'}} className={'bg-white text-center p-5 text-muted'}>
          <p className={'mb-5'}>info@mysite.com</p>
          <p>2022©VanHoth illiterate 不识 版權所有</p>
        </div>
      </div>
  );
};

export default IndexPage;