import React from 'react';
// @ts-ignore
import img from '../../assets/1.jpg'

import './IU.css'
import {useTranslation} from "react-i18next";

const IUtopianThough = () => {
  const {t} = useTranslation();
  return (
      <div>
        <div>
          <div className={'d-flex justify-content-center'} style={{backgroundColor: '#812627'}}>
            <img src={img} className={'iu-img'}/>
          </div>

          <div className={' iu-p1 py-5'} style={{backgroundColor: '#c8b8a5'}}>
            <div className="container">
              <p>
                {t('iu-t1-description1')}
              </p>

              <br/>
              <p>
                {t('iu-t1-description2')}
              </p>

              <br/>
              <p className={'fst-italic'}>
                {t('iu-t1-description3')}
              </p>
              <h1 className={'fst-italic text-center fw-bold'}> {t('iu-t1-title')}</h1>
            </div>
          </div>


          <div className={'iu-t2'}>
            <div className="container">
              <div className={'row  m-0 py-5'}>
                <div className={'col-lg-3  col-12 title d-flex text-start'}>
                  <div className={'flex-grow-1 m-0 mb-5'}>
                    Lesson 1 :<br/> {t('iu-lesson1-title')}
                  </div>

                </div>
                <div className={'col-lg-9 col-12 '}>
                  <div className={'description ps-0 ps-lg-5'}>
                    <h2>
                      Theme
                    </h2>
                    <p>
                      {t('iu-lesson1-theme')}
                    </p>
                    <br/>
                    <h2>Content</h2>
                    <p>
                      {t('iu-lesson1-content')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className={'iu-t2 iu-t3'}>
            <div className="container">
              <div className={'row  m-0 py-5'}>
                <div className={'col-lg-9 col-12 order-2 order-lg-0'}>
                  <div className={'description'}>
                    <h2>
                      Theme
                    </h2>
                    <p>
                      {t('iu-lesson2-theme')}
                    </p>
                    <br/>
                    <h2>Content</h2>
                    <p>
                      {t('iu-lesson2-content')}
                    </p>
                  </div>
                </div>
                <div className={'col-lg-3 col-12 order-1 order-lg-0 ps-lg-5 title d-flex text-start'}>
                  <div className={'flex-grow-1 m-0 mb-5'}>
                    Lesson 2 :<br/> {t('iu-lesson2-title')}
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className={'iu-t2'}>
            <div className="container">
              <div className={'row  m-0 py-5'}>
                <div className={'col-lg-3  col-12 title d-flex text-start'}>
                  <div className={'flex-grow-1 m-0 mb-5'}>
                    Lesson 3 :<br/>{t('iu-lesson3-title')}
                  </div>

                </div>
                <div className={'col-lg-9 col-12 '}>
                  <div className={'description ps-0 ps-lg-5'}>
                    <h2>
                      Theme
                    </h2>
                    <p>
                      {t('iu-lesson3-theme')}
                    </p>
                    <br/>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className={'iu-t2 iu-t3'}>
            <div className="container">
              <div className={'row  m-0 py-5'}>
                <div className={'col-lg-9 col-12 order-2 order-lg-0'}>
                  <div className={'description'}>
                    <h2>
                      Theme
                    </h2>
                    <p>
                      {t('iu-lesson4-theme')}
                    </p>
                    <br/>
                    <h2>Content</h2>
                    <p>
                      {t('iu-lesson4-content')}
                    </p>
                  </div>
                </div>
                <div className={'col-lg-3 col-12 order-1 order-lg-0 ps-lg-5 title d-flex text-start'}>
                  <div className={'flex-grow-1 m-0 mb-5'}>
                    Lesson 4 :<br/> {t('iu-lesson4-title')}
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className={'iu-t2'}>
            <div className="container">
              <div className={'row  m-0 py-5'}>
                <div className={'col-lg-3  col-12 title d-flex text-start'}>
                  <div className={'flex-grow-1 m-0 mb-5'}>
                    Lesson 5 :<br/>{t('iu-lesson5-title')}
                  </div>

                </div>
                <div className={'col-lg-9 col-12 '}>
                  <div className={'description ps-0 ps-lg-5'}>
                    <h2>
                      Theme
                    </h2>
                    <p>
                      {t('iu-lesson5-theme')}

                    </p>
                    <br/>
                    <h2>Content</h2>
                    <p>
                      {t('iu-lesson5-content')}

                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className={'iu-t2 iu-t3'}>
            <div className="container">
              <div className={'row  m-0 py-5'}>
                <div className={'col-lg-9 col-12 order-2 order-lg-0'}>
                  <div className={'description'}>
                    <h2>
                      Theme
                    </h2>
                    <p>
                      {t('iu-lesson6-theme')}
                    </p>
                    <br/>
                    <h2>Content</h2>
                    <p>
                      {t('iu-lesson6-content')}
                    </p>
                  </div>
                </div>
                <div className={'col-lg-3 col-12 order-1 order-lg-0 ps-lg-5 title d-flex text-start'}>
                  <div className={'flex-grow-1 m-0 mb-5'}>
                    Lesson 6 :<br/> {t('iu-lesson6-title')}
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className={'iu-t2'}>
            <div className="container">
              <div className={'row  m-0 py-5'}>
                <div className={'col-lg-3  col-12 title d-flex text-start'}>
                  <div className={'flex-grow-1 m-0 mb-5'}>
                    Lesson 7 :<br/>{t('iu-lesson7-title')}
                  </div>

                </div>
                <div className={'col-lg-9 col-12 '}>
                  <div className={'description ps-0 ps-lg-5'}>
                    <h2>
                      Theme
                    </h2>
                    <p>
                      {t('iu-lesson7-theme')}

                    </p>
                    <br/>
                    <h2>Content</h2>
                    <p>
                      {t('iu-lesson7-content')}

                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className={'iu-t2 iu-t3'}>
            <div className="container">
              <div className={'row  m-0 py-5'}>
                <div className={'col-lg-9 col-12 order-2 order-lg-0'}>
                  <div className={'description'}>
                    <h2>
                      Theme
                    </h2>
                    <p>
                      {t('iu-lesson8-theme')}
                    </p>
                    <br/>
                    <h2>Content</h2>
                    <p>
                      {t('iu-lesson8-content')}
                    </p>
                  </div>
                </div>
                <div className={'col-lg-3 col-12 order-1 order-lg-0 ps-lg-5 title d-flex text-start'}>
                  <div className={'flex-grow-1 m-0 mb-5'}>
                    Lesson 8 :<br/> {t('iu-lesson8-title')}
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className={'iu-t4'} style={{backgroundColor: '#393636'}}>
            <div className={'container '}>
              <div className={'row py-5 '}>
                <div className={'col-lg-6 col-12'}>
                  <h1>
                    {t('iu-debate-title')}
                  </h1>
                  <p>
                    {t( 'iu-debate-description')}
                  </p>
                </div>

                <div className="col-lg-6 col-12">
                  <div>
                    <h2 className={'mb-3'}>{t( 'iu-debate-Prop-title')}</h2>
                    <ul>
                      <li>{t( 'iu-debate-Prop-point1')}</li>
                      <li>{t( 'iu-debate-Prop-point2')}</li>
                      <li>{t( 'iu-debate-Prop-point3')}</li>
                      <li>{t( 'iu-debate-Prop-point4')}</li>
                    </ul>
                  </div>
                  <br/>
                  <div>
                    <h2 className={'mb-3'}>
                      {t( 'iu-debate-Oppo-title')}
                    </h2>
                    <ul>
                      <li>{t( 'iu-debate-Oppo-point1')}</li>
                      <li>{t( 'iu-debate-Oppo-point2')}</li>
                      <li>{t( 'iu-debate-Oppo-point3')}</li>

                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default IUtopianThough;