import React from 'react';
import './HomePage.css'
import {useTranslation} from "react-i18next";


const HomePage = () => {

  const {t, i18n} = useTranslation();
  console.log(i18n.language)
  return (
      <div className={'homepage-container px-0 px-lg-5 pb-0 pb-lg-5'}>
        <div className={'container'}>
          <video className={'w-100'} controls>
            <source src="../../assets/homepage.mp4" type="video/mp4"/>
            您的浏览器不支持 video 标签。
          </video>
          <div className={'text-start text-uppercase homepage-descript'}>
            {
              i18n.language === 'en' ?
                  <div>
                    <h2>We reside in the realm of ideas</h2>
                    <h3> Desperate to escape the info cocoon.</h3>
                    <h3>Our success or our failure is undecided,</h3>
                    <h3>But through our efforts to engage in rational discourse,</h3>

                    <h2>We shall maintain.</h2>
                    <h3>Have you the courage to burden yourself with this wise task?</h3>
                    <h3>Only through action,</h3>
                    <h3>But through our efforts to engage in rational discourse,</h3>

                    <h2>shall we convene.</h2>
                  </div> :
                  <div>
                    <h3>一个有些理想化的读书会，妄想逃离纷杂单一的信息茧房</h3>
                    <h3>也许失败，也许成功</h3>
                    <h3>尚未失败，尚未成功</h3>
                    <h3>抓住每个反思的可能 让主动思考成为一种习惯</h3>
                    <br/>
                    <h3>不识来者，亦不问归途</h3>
                    <h3>汝 可愿一叙？</h3>
                    <br/>
                  </div>

            }

          </div>

        </div>
        <div className={'homepage-season p-lg-5 p-4'}>
          <div className={'container'}>
            <h1>
              {t('hp-lesson-title')}
            </h1>
            <div className={'row text-start'}>

              <div className={'col-lg-3 col-12 mb-lg-5 '}>
                <p>Lesson 1</p>
                <h2>
                  {t('hp-lesson1-title')}
                </h2>
              </div>
              <div className={'col-lg-9 col-12 align-self-center  t3 '}>
                {t('hp-lesson1-description')}
              </div>

              <div className={'col-lg-3 col-12 mb-lg-5 mt-5'}>
                <p>Lesson 2</p>
                <h2>
                  {t('hp-lesson2-title')}
                </h2>
              </div>
              <div className={'col-lg-9 col-12 align-self-center  t3 '}>
                {t('hp-lesson2-description')}
              </div>


              <div className={'col-lg-3 col-12 mb-lg-5 mt-5'}>
                <p>Lesson 3</p>
                <h2>
                  {t('hp-lesson3-title')}
                </h2>
              </div>
              <div className={'col-lg-9 col-12 align-self-center t3 '}>
                {t('hp-lesson3-description')}
              </div>


              <div className={'col-lg-3 col-12 mb-lg-5 mt-5'}>
                <p>Lesson 4</p>
                <h2>
                  {t('hp-lesson4-title')}
                </h2>
              </div>
              <div className={'col-lg-9 col-12 align-self-center t3 '}>
                {t('hp-lesson4-description')}
              </div>

              <div className={'col-lg-3 col-12 mb-lg-5 mt-5'}>
                <p>Lesson 5</p>
                <h2>
                  {t('hp-lesson5-title')}
                </h2>
              </div>
              <div className={'col-lg-9 col-12 align-self-center  t3 '}>
                {t('hp-lesson5-description')}
              </div>


              <div className={'col-lg-3 col-12 mb-lg-5 mt-5'}>
                <p>Lesson 6</p>
                <h2>
                  {t('hp-lesson6-title')}
                </h2>
              </div>
              <div className={'col-lg-9 col-12 align-self-center  t3 '}>
                {t('hp-lesson6-description')}
              </div>


              <div className={'col-lg-3 col-12 mb-lg-5 mt-5'}>
                <p>Lesson 7</p>
                <h2>
                  {t('hp-lesson7-title')}
                </h2>
              </div>
              <div className={'col-lg-9 col-12 align-self-center  t3 '}>
                {t('hp-lesson7-description')}
              </div>

              <div className={'col-lg-3 col-12 mb-lg-5 mt-4'}>
                <p>Lesson 8</p>
                <h2>
                  {t('hp-lesson8-title')}
                </h2>
              </div>
              <div className={'col-lg-9 col-12 align-self-center  t3 '}>
                {t('hp-lesson8-description')}
              </div>


            </div>
          </div>

        </div>

      </div>
  );
};

export default HomePage;